Vue.component('sign-up-for-training-component', {
    props: {
        url: String,
        updateUrl: String,
    },
    mixins: [Mixins.dataset],
    data() {
        return {
            selectedTrainingId: null,
            selectedTraining: null,
            signUpConfirmation: false,
            reason: '',
            guest: '',
            showStatus: true,
        }
    },
    computed: {
        signedUpTrainings: function () {
            return this.data.filter(i => ['signed_up', 'reserve', 'confirmed'].includes(i.status));
        },
        signedUpOrSelectedTrainings: function () {
            let trainings = this.data.filter(i => ['signed_up', 'reserve', 'confirmed'].includes(i.status));
            if (this.selectedTrainingId) {
                trainings.push(this.getTraining(this.selectedTrainingId));
            }
            return trainings;
        },
        canSignUp: function () {
            return this.maxSignUps > this.signedUpTrainings.length;
        },
        availableTrainings: function () {
            return this.data.filter(i => !['signed_up', 'reserve', 'confirmed'].includes(i.status));
        },
        maxSignUps: function () {
            return this.rawData.training_type.sign_ups_per_volunteer;
        }
    },
    created: function () {
        this.firstGet();
    },
    mounted: function () {
    },
    methods: {
        getTraining(externalId) {
            return this.data.find(function (i) { return i.external_id === externalId; })
        },
        selectTraining(external_id, training) {
            if (!this.canSignUp) return;
            this.selectedTrainingId = external_id;
            this.selectedTraining = training;
        },
        changeStatus(status, training) {
            if (status === 'signed_up' && this.selectedTrainingId === null) return;
            if (status === 'signed_out' && this.signedUpTrainings.length === 0) return;

            if (status === 'signed_out' && this.getTraining(training.external_id).ask_confirmation === false) {
                this.getTraining(training.external_id).ask_confirmation = true;
                return;
            }

            this.requestPut(this.updateUrl, {
                'training': training.external_id,
                'status': status,
                'reason': this.reason,
                'guest': training.guest,
            });

            this.status = 'pending';
            this.selectedTrainingId = null;
            this.signUpConfirmation = true;
            this.showStatus = false;
        },
        makeSecondOptionFirstOption(externalId, status) {
            this.requestPut(this.updateUrl, {
                'training': externalId,
                'status': status,
                'sign_up_option': 1,
            });
        },
        isTrainingSignedUp(external_id) {
            return this.signedUpTrainings.some(i => i.external_id === external_id);
        }
    }
});
